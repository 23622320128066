@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import "components/fonts";

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

.logo {
  height: rem-calc(25);

  @include breakpoint(medium) {
    height: rem-calc(40);
  }
}

p {
  @include breakpoint(medium) {
    text-align: justify;
  }
  // hyphens: auto;
}

#team {
  .accordion {
    p {
      text-align: left;
    }
  }
}

address {
  padding-bottom: 1rem;
}

footer {
  border-top: 2px solid $light-gray;
  height: 5rem;
  display: flex;
}

footer > div {
  margin: auto;
}

// avoid Flash of Unstyled Content aka showing both menus when loading page
.nojs {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}



header {
  background-color: $topbar-background;
}

.sticky-container {
  width: 100%;
}

.top-bar {

  .top-bar-left {
    font-family: $body-font-family;
    font-size: rem-calc(26);
    color: $white;
    letter-spacing: rem-calc(8);
  }

  a {
    color: $white;
    font-size: $global-font-size * 1.2;
    &:hover {
      color: $green_light;
      transition: color 0.2s ease-in;
    }
  }

}

.sticky-container {
  .is-stuck {
    box-shadow: 0 4px 10px -2px rgba(128,128,128,0.68);
    transition: background .20s ease-in-out, box-shadow .25s ease-in-out;
  }
}



section {

  padding-top: rem-calc(50);
  @include breakpoint(medium) {
    padding-top: rem-calc(75);
  }

  div.row.column:first-child {

    img {

      margin-bottom: rem-calc(40);

    }
  }

  h1, h2 {
    margin-bottom: rem-calc(20);
  }

  &#welcome {
    padding-top: rem-calc(15);

  }

  &#team {
    table {
      tbody {
        tr {
          td {
            vertical-align: top;
          }
        }
      }
    }
  }

}

.accordion-title {
  h3, h4 {
    line-height: inherit;
    font-size: inherit;
    font-family: inherit;
    margin-bottom: inherit;
  }
  @include breakpoint(medium) {
    font-size: rem-calc(24);
  }
  &::before {
    margin-top: rem-calc(-12);
  }
}

a.button {
  font-size: rem-calc(16);
}

.portrait {
  margin-bottom: rem-calc(15);
}

h4 {
  margin-top: rem-calc(16);
}

#privacy {
  .deletionrights {
    li {
      list-style: lower-latin;
    }
  }

  .data {
    li {
      list-style: disc;
    }
  }

  .information {
    li {
      list-style: decimal;
    }
  }

  .counter1-reset {
    counter-reset: regulation1;
  }

  li {
    list-style: none;
  }
  .counter1 {
    h3 {
      display: inline;
    }
    &:before {
      content: counters(regulation1, ".") ". ";
      counter-increment: regulation1;

      @include breakpoint(small only) {
        font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
      }
      @include breakpoint(medium) {
        font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
      }
    }
  }

  .counter2-reset {
    counter-reset: regulation2;
  }

  .counter2 {
    h4 {
      display: inline;
    }
    &:before {
      counter-increment: regulation2;
      content: counters(regulation2, ".") ". ";

      @include breakpoint(small only) {
        font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
      }
      @include breakpoint(medium) {
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
      }
    }
  }

  .counter3-reset {
    counter-reset: regulation3;
  }

  .counter3 {
    h5 {
      display: inline;
    }
    &:before {
      counter-increment: regulation3;
      content: counters(regulation3, ".") ". ";

      @include breakpoint(small only) {
        font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
      }
      @include breakpoint(medium) {
        font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
      }
    }
  }

}

picture {
  img {
    width: 100%;
  }
}
