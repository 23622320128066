$font-path: '../fonts/' !default;

@font-face {
  font-family: 'Noto Serif';
  src: url('#{$font-path}NotoSerif-Bold.woff2') format('woff2'),
  url('#{$font-path}NotoSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('#{$font-path}NotoSerif-BoldItalic.woff2') format('woff2'),
  url('#{$font-path}NotoSerif-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('#{$font-path}NotoSerif.woff2') format('woff2'),
  url('#{$font-path}NotoSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('#{$font-path}NotoSerif-Italic.woff2') format('woff2'),
  url('#{$font-path}NotoSerif-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('#{$font-path}NotoSans-Italic.woff2') format('woff2'),
  url('#{$font-path}NotoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('#{$font-path}NotoSans.woff2') format('woff2'),
  url('#{$font-path}NotoSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('#{$font-path}NotoSans-BoldItalic.woff2') format('woff2'),
  url('#{$font-path}NotoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('#{$font-path}NotoSans-Bold.woff2') format('woff2'),
  url('#{$font-path}NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
